import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

import Back from '../../assets/icons/back.png'
import './showcase.css'
import categories from '../../config/categories'
import projects from '../../config/projects'
import { formatText  } from '../../utils/format-text'

const Showcase = ({ match }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { project } = match.params;
  const { title, category, copy, images } = projects[project]
  const { colour } = categories[category]

  return (
    <article className="showcase">
      <Link to={`/#${project}`} className="showcase__menu bg--dark-pink">
        <img src={Back} alt="to projects" />
      </Link>
      <div className="showcase__description">
        <h1 className="showcase__title">
          {formatText(title)}
        </h1>
        <p className="showcase__copy">
          {formatText(copy)}
          <br />
          <Link to={`/projects?category=${category}`} className={`showcase__category bg--${colour}`}>
            {category}
          </Link>
        </p>
      </div>
      <div className={`showcase__gallery bg--${colour}`}>
        {
          images.showcase.map((url, index) => <img key={index} src={url} alt="" />)
        }
      </div>
    </article>
  )
}

export default withRouter(Showcase)
