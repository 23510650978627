import React from 'react'
import { Link } from 'react-router-dom'

import Back from '../../assets/icons/back.png'
import './about.css'
import config from '../../config/default'
import { formatText  } from '../../utils/format-text'
import LinkedIn from '../../assets/icons/linkedin.png'
import Mail from '../../assets/icons/mail.png'
import Portfolio from '../../assets/icons/portfolio.png'

const About = () => {

  const { title, copy, image, colour } = config.about

  return (
    <article className="about">
      <Link to="/" className="about__menu bg--blue">
        <img src={Back} alt="to home" />
      </Link>
      <div className="about__description">
        <h1 className="about__title">
          {formatText(title)}
        </h1>
        <p className="about__copy">{formatText(copy)}</p>
        <div className="about__links">
          <a href="https://uk.linkedin.com/in/joanacastilho" target="_blank" rel="noopener noreferrer" title="LinkedIn">
            <img src={LinkedIn} alt="" />
          </a>
          <button href="#" rel="nofollow" onClick={() => window.location.href = "mailto:joanaxcoelho@gmail.com"}>
            <img src={Mail} alt="" />
          </button>
          <a href="http://www.jocastilho.com" target="_blank" rel="noopener noreferrer" title="Portfolio">
            <img src={Portfolio} alt="" />
          </a>
        </div>
      </div>
      <div className={`about__image bg--${colour}`}
        style={{
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center'
        }}
      />
    </article>
  )
}

export default About
