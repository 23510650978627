import React from 'react'
import { withRouter } from 'react-router-dom'
import Back from '../../assets/icons/back.png'
import Projects from './index'
import './projects-root.css'
import categories from '../../config/categories.json'

const ProjectsRoot = ({ history, match }) => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const category = searchParams.get('category');
  const { colour } = categories[category]
  return (
    <div className="projects-root">
        <button
          onClick={history.goBack}
          className={`projects-root__menu bg--${colour}`}
        >
          <img src={Back} alt="to home" />
        </button>
      <Projects />
    </div>
  )
}

export default withRouter(ProjectsRoot)
