import React, { useState, useEffect } from 'react'
import Media from 'react-media';
import { Link } from 'react-router-dom';
import './splash.css'

import config from '../../config/default'

const { title: defaultTitle, subtitle } = config.splash;

const Splash = () => {

  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const name = searchParams.get('hey');

  const [title, setTitle] = useState(name ? `hey ${name}` : defaultTitle)

  useEffect(() => {
    if (name) {
      setTimeout(() => {
        setTitle(defaultTitle)
      }, 2000)
    }
  }, [name])

  return (
    <div className="splash">
      <Link to="/about" className="splash__nav-item">Who I am</Link>
      <header className="splash__header">
        <h1 className="splash__title">{title}</h1>
        <p className="splash__subtitle">{subtitle}</p>
      </header>
      <Media query="(min-width: 768px)">
        <div className="splash__scroll"></div>
      </Media>
    </div>
  )
}

export default Splash
