import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Splash from '../splash'
import Projects from '../projects'
import ProjectsRoot from '../projects/projects-root'
import Showcase from '../showcase'
import About from '../about'
import './app.css'

const app = () => (
  <Router>
    <Route exact path="/" render={() => (
      <Fragment>
        <Splash />
        <Projects />
      </Fragment>
    )} />
    <Route path="/showcase/:project" component={Showcase} />
    <Route path="/about" component={About} />
    <Route path="/projects" component={ProjectsRoot} />
  </Router>
)

export default app
