import React from 'react'
import { Link } from 'react-router-dom'

import './project.css'
import projects from '../../config/projects'
import categories from '../../config/categories'

const Small = ({ project }) => {

  const { title, category, images } = projects[project]
  const { colour } = categories[category]

  return (
    <Link to={`/showcase/${project}`} className={`project bg--${colour} project--small`}>
      <img className="project__image" src={images.small} alt={title} />
    </Link>
  )
}

export default Small
