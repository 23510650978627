import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import './project.css'
import projects from '../../config/projects'
import categories from '../../config/categories'

const Large = ({ project, size }) => {

  const [hover, setHover] = useState(false);

  const { title, category, images } = projects[project]
  const { label, colour } = categories[category]

  return (
    <Link
      id={project}
      to={`/showcase/${project}`}
      className={`project bg--${colour} project--${size}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundImage: hover ? `url(${images[size]})` : null,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      {
        hover
        ? null
        : (
          <Fragment>
            <h2 className="project__title">{title}</h2>
            <p className="project__subtitle">{label}</p>
          </Fragment>
        )
      }
    </Link>
  )
}

export default Large
