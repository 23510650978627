import React from 'react'
import Media from 'react-media';

import './projects.css'
import Large from './large'
import Small from './small'
import config from '../../config/default'
import projects from '../../config/projects'

const Projects = () => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const category = searchParams.get('category');

  return (
    <div id="projects" className="projects bg--white">
      <section className="projects__grid">
        <Media query="(min-width: 768px)">
          {
            isDesktop => config.projects
              .filter(project => {
                if (category) {
                  return projects[project.project].category === category
                } else {
                  return true
                }
              })
              .map(({ project, size }) => (
                isDesktop
                ? <Large key={project} project={project} size={size} />
                : <Small key={project} project={project} />
            ))
          }
        </Media>
      </section>
    </div>
  )
}

export default Projects
